import { formatDateForApi } from '../utils/dates';
import api from '../api/Acutame';

export default class BookingService {
  organizationId;

  constructor(organizationId) {
    this.organizationId = organizationId;
  }

  getBookings(page, size, showHistory, sort, query) {
    return api.getBookings(
      this.organizationId,
      page,
      size,
      sort,
      showHistory,
      query
    );
  }

  postBooking(booking) {
    booking.bookingDate = formatDateForApi(booking.bookingDate);
    booking.organizationId = this.organizationId;
    return api.postBooking(booking);
  }

  editBooking(booking, bookingId) {
    booking.bookingDate = formatDateForApi(booking.bookingDate);
    booking.organizationId = this.organizationId;
    return api.editBooking(booking, bookingId);
  }

  acceptBooking(booking) {
    return api.acceptBooking(booking);
  }

  rejectBooking(booking) {
    return api.rejectBooking(booking);
  }

  cancelBooking(booking) {
    return api.cancelBooking(booking.id);
  }
}
